<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	DialogClose,
	DialogContent,
	type DialogContentEmits,
	type DialogContentProps,
	DialogOverlay,
	DialogPortal,
	useForwardPropsEmits,
} from 'radix-vue';
import { type SheetVariants, sheetVariants } from '.';
import { cn } from '@laam/lib/utils';
import { PhX } from '@phosphor-icons/vue';

interface SheetContentProps extends DialogContentProps {
	class?: HTMLAttributes['class'];
	side?: SheetVariants['side'];
	closeIconLeft?: boolean;
}

defineOptions({
	inheritAttrs: false,
});

const props = withDefaults(defineProps<SheetContentProps>(), {
	closeIconLeft: false,
	side: 'right',
	class: '',
});

const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
	const { class: _, side, ...delegated } = props;

	return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<DialogPortal>
		<DialogOverlay
			class="fixed inset-none z-50 bg-gray-950/70 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-none data-[state=open]:fade-in-none"
		/>
		<DialogContent
			:class="cn(sheetVariants({ side }), props.class)"
			v-bind="{ ...forwarded, ...$attrs }"
		>
			<DialogClose
				v-if="closeIconLeft === true"
				class="absolute left-xl top-xl rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:pointer-events-none p-md"
			>
				<PhX size="24" class="fill-gray-500" weight="regular" />
			</DialogClose>
			<slot />

			<DialogClose
				v-if="closeIconLeft === false"
				class="absolute right-xl top-xl rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:pointer-events-none p-md"
			>
				<PhX size="24" class="fill-gray-500" weight="regular" />
			</DialogClose>
			<slot name="rightIcon" />
		</DialogContent>
	</DialogPortal>
</template>
