import { type VariantProps, cva } from 'class-variance-authority';

export { default as Sheet } from './Sheet.vue';
export { default as SheetTrigger } from './SheetTrigger.vue';
export { default as SheetClose } from './SheetClose.vue';
export { default as SheetContent } from './SheetContent.vue';
export { default as SheetHeader } from './SheetHeader.vue';
export { default as SheetTitle } from './SheetTitle.vue';
export { default as SheetDescription } from './SheetDescription.vue';
export { default as SheetFooter } from './SheetFooter.vue';
export { default as SheetBody } from './SheetBody.vue';

export const sheetVariants = cva(
	/*tw*/ 'flex flex-col fixed z-50 pt-5xl pb-3xl bg-white shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
	{
		variants: {
			side: {
				top: /* tw */ 'inset-x-none top-none border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
				bottom:
					/* tw */ 'w-full inset-x-none bottom-none border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom max-h-full',
				left: /* tw */ 'inset-y-none left-none h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
				right:
					/* tw */ 'inset-y-none right-none h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
			},
		},
		defaultVariants: {
			side: 'right',
		},
	},
);

export type SheetVariants = VariantProps<typeof sheetVariants>;
