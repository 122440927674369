<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
	<div
		:class="
			cn(
				'flex flex-col gap-y-2 text-center sm:text-left px-3xl pb-3xl border-b border-gray-200',
				props.class,
			)
		"
	>
		<slot />
	</div>
</template>
